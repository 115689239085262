import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Invoice: [
    {
      InvoiceId: 0,
      BookingReference: 0,
      VoucherNumber: "",
      CheckInDate: "",
      CheckOutDate: "",
      InvoiceNumber: "",
      InvoiceDate: "",
      InvoiceExchangeRate: 0,
      InvoiceCurrency: 0,
      InvoiceAmountWithoutVAT: 0,
      InvoiceVATAmount: 0,
      InvoiceAmountWithVAT: 0,
      InvoiceUploadedBy: "",
      InvoiceUploadDate: "",
      CreditNoteNumber: "",
      CreditNoteDate: "",
      CreditNoteExchangeRate: 0,
      CreditNoteAmountWithoutVAT: 0,
      CreditNoteVATAmount: 0,
      CreditNoteAmountWithVAT: 0,
      CreditNoteAmountWithVATInContractCurrency: 0,
      Status: "",
      CreditNoteUploadedBy: "",
      CreditNoteEnteredDate: "",
      CreditNoteCurrencyID: "",
      CreditNoteID: 0,
      Remarks: "",
      CreditNoteRemarks: "",
      Assignee: null,
      HasCreditNote: false,
      VoucherImage: null,
      InvoiceImage: null,
      CreditNoteImage: null,
      PayoutCurrencyID: null,
      PayoutExchangeRate: null,
    }
  ],
};

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    setInvoices: (state, action) => {
      state.Invoice = action.payload;
    },

    updateInvoice: (state, action) => {
      const updatedInvoice = action.payload;
      state.Invoice = state.Invoice.map(invoice =>
        invoice.InvoiceId === updatedInvoice.InvoiceId
          ? { ...invoice, ...updatedInvoice }
          : invoice
      );
    },

    updateCreditNote: (state, action) => {
      const updatedCreditNote = action.payload;
      state.Invoice = state.Invoice.map(invoice =>
        invoice.CreditNoteID === updatedCreditNote.CreditNoteID
          ? { ...invoice, ...updatedCreditNote }
          : invoice
      );
    },

    deleteInvoice: (state, action) => {
      const invoiceId = action.payload;
      state.Invoice = state.Invoice.filter(invoice => invoice.InvoiceId !== invoiceId);
    },

    clearInvoice: (state) => {
      state.Invoice = initialState.Invoice;
    },
  },
});

export const { setInvoices, updateInvoice, deleteInvoice, clearInvoice, updateCreditNote } = invoiceSlice.actions;

export default invoiceSlice.reducer;
