import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  VoucherNumber: "",
  InvoiceNumber: "",
  CreditNoteNumber: "",
};

const searchContentSlice = createSlice({
  name: "searchContent",
  initialState,
  reducers: {
    updateSearchContentField: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },

    clearInvoiceSearchFields: (state) => {
      state.InvoiceNumber = "";
      state.CreditNoteNumber = "";
    },
  },
});

export const { updateSearchContentField, clearInvoiceSearchFields } = searchContentSlice.actions;

export default searchContentSlice.reducer;
