import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";
import voucherReducer from "src/state/invoice/VoucherSlice";
import invoiceReducer from "src/state/invoice/InvoiceSlice";
import searchContentReducer from "src/state/searchContent/SearchContentSlice";
import invoiceSearchResultContentReducer from "src/reducers/InvoiceSearchResultContentReducer";
import outstandingStatementResultContentReducer from "src/reducers/OutstandingStatementResultContentReducer";

// Persist configuration
const persistConfig = {
  key: "root",
  storage: sessionStorage,
};

const rootReducer = combineReducers({
  voucher: voucherReducer,
  invoice: invoiceReducer,
  searchContent: searchContentReducer,
  invoiceSearchResultContent: invoiceSearchResultContentReducer,
  outstandingStatementResultContent: outstandingStatementResultContentReducer,
  // add other reducers
});

// Wrap reducers with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store with persisted reducer
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };
