const initialState = {
    outstandingStatementResults: {
        OutstandingStatements : [],
    },
  };

  function outstandingStatementResultContentReducer(state = initialState.outstandingStatementResults, action) {
    switch (action.type) {
      case "ADD_OUTSTANDING_STATEMENTS":
        return {
          ...state,
          OutstandingStatements: action.payload,
        };
      case "CLEAR_OUTSTANDING_STATEMENTS":
        return {
            OutstandingStatements: [],
        };
      default:
        return state;
    }
  }
  
  export default outstandingStatementResultContentReducer;
  