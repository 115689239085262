const initialState = {
  searchResults: {
    Invoices: [],
  },
};

function invoiceSearchResultContentReducer(state = initialState.searchResults, action) {
  switch (action.type) {
    case "ADD_INVOICE":
      return {
        ...state,
        Invoices: action.payload,
      };

    case "UPDATE_INVOICE":
      const updatedInvoice = action.payload;
      return {
        ...state,
        Invoices: state.Invoices.reduce((acc, invoice) => {
          if (invoice.InvoiceID === updatedInvoice.InvoiceID) {
            if (
              updatedInvoice.BookingSupplierInvoiceVerificationDetail
                .VerificationStatus === 2
            ) {
              return acc;
            }
            return [...acc, { ...invoice, ...updatedInvoice }];
          }
          return [...acc, invoice];
        }, []),
      };

    case "DELETE_INVOICE":
      return {
          ...state,
          Invoices: state.Invoices.filter(invoice => invoice.InvoiceID !== action.payload)
      };

    case "CLEAR_ALL":
      return {
        Invoices: [],
      };
    default:
      return state;
  }
}

export default invoiceSearchResultContentReducer;
